<template>
    <uw-content title="用户访问频率" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button @click="TableGet()" type="success" size="mini">刷新</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false" :show-footer="false">

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :loading="loading" :data="statistics" height="100%">
                    <!-- 头像 -->
                    <vxe-column width="150" field="avatar" title="用户名">
                        <template #header>
                            <el-link icon="el-icon-sort" :underline="false" :type="order.column == 'name' ? 'primary' : ''" @click="TableSort('name')">用户名</el-link>
                        </template>
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" v-if="row.avatar" :src="row.avatar" />
                                <el-avatar style="margin-right: 5px;" :size="26" v-else> {{ row.name[0] }} </el-avatar>
                                {{  row.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 近期登入时间 -->
                    <vxe-column field="statistics">
                        <template #header>
                            <el-link icon="el-icon-sort" :underline="false" :type="order.column == 'updated_at' ? 'primary' : ''" @click="TableSort('updated_at')">近期登入时间</el-link>
                        </template>
                        <template #default="{ row }">
                            {{ row.updated_at }}
                        </template>
                    </vxe-column>

                    <!-- 登入次数 -->
                    <vxe-column min-width="150" field="updated_at">
                        <template #header>
                            <el-link icon="el-icon-sort" :underline="false" :type="order.column == 'updated_at' ? 'primary' : ''" @click="TableSort('updated_at')">僵尸用户</el-link>
                        </template>
                        <template #default="{ row }">
                            <el-link v-if="row.zombie == 0" type="success">正常</el-link>
                            <el-link v-if="row.zombie > 0 && row.zombie < 8" type="primary">连续 <b>{{ row.zombie }}天</b> 未登入</el-link>
                            <el-link v-if="row.zombie > 7 && row.zombie < 31" type="warning">连续 <b>{{ row.zombie }}天</b> 未登入</el-link>
                            <el-link v-if="row.zombie > 30" type="danger">连续 <b>{{ row.zombie }}天</b> 未登入</el-link>
                        </template>
                    </vxe-column>

                    <!-- 登入次数 -->
                    <vxe-column width="150" field="statistics">
                        <template #header>
                            <el-link icon="el-icon-sort" :underline="false" :type="order.column == 'statistics' ? 'primary' : ''" @click="TableSort('statistics')">访问频率</el-link>
                        </template>
                        <template #default="{ row }">
                            {{ row.statistics }} / 次
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>
            
        </uw-table>

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            statistics: [],

            order: {
                method: 'asc',
                column: 'updated_at'
            },
        }
    },

    mounted () {
        this.TableGet()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据获取
        |--------------------------------------------------
        |
        */
        
        TableGet() {
            this.loading = true
            this.$http.post('/9api/statistics-use/login', { order: this.order }).then((rps) => {
                this.statistics = rps.data.map(element => {
                    element.zombie = Math.floor((new Date().getTime() - new Date(element.updated_at).getTime()) / 86400000)
                    return element
                })
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            if (this.order.column == column) {
                this.order.method == 'desc' ? this.order.method = 'asc' : this.order.method = 'desc'
                this.TableGet()
            } else {
                this.order.column = column
                this.TableGet()
            }
        }
    }
}
</script>